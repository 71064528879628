import React, { useState } from "react"
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import { Link } from 'gatsby';
import { graphql, useStaticQuery } from 'gatsby'
import { getImage, StaticImage } from "gatsby-plugin-image"

import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'
import { PlusCircle } from 'react-feather';

const LandingB = () => {

    const [showDemo, setShowDemo] = useState();

    const { placeholderImage } = useStaticQuery(
        graphql`
          query {
            placeholderImage: file(relativePath: { eq: "lp/main_02.png" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 1000,
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            },
          }
        `
    )
    const image1 = getImage(placeholderImage);
    const bgImage = convertToBgImage(image1);


    return (
        <Layout showDemo={showDemo}>
            <Seo title='LandingA' />
            <div>
                <BackgroundImage
                    Tag="section"
                    fluid
                    {...bgImage}
                    preserveStackingContext
                >
                    <section className='lp-intro'>
                        <div className="banner-stripe">
                            <div className="banner-container">
                                <div className="ml-md-4">
                                    <div className="banner">
                                        <h1>Data analysis <span> in a </span> <br /> post-pandemic world!</h1>
                                    </div>
                                    <div className='triangle'></div>
                                </div>
                            </div>
                        </div>
                    </section>
                </BackgroundImage>
            </div>

            <section className='learn-more'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-11 mx-auto">
                            <div className="row mb-4">
                                <div className="col-md-6 mb-4 mb-md-0">
                                    <div className="increase">
                                        <p>Isn’t it time to increase</p>
                                        <h2 className='title'>Creativity <span> and</span> flexibility</h2>
                                        <p>in your data science tool?</p>
                                        <Link className='btn btn-block btn-outline-grey mt-3' to='/solutions'>Learn more</Link>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="quote-panel">
                                        <h4>A recent study by Refinitiv highlighted the fall-out of the pandemic on the quant industry.</h4>
                                        <span className='quote-top'></span>
                                        <p className='quote'>"Refinitiv said 72% is such investors were hurt by the pandemic. Some 12% declared their models obsolete and 15% were building new ones."</p>
                                        <span className='quote-bottom'></span>
                                        <StaticImage placeholder='none' src="../../images/lp/pete.png" alt='Pete Millington' />
                                        <p className='author'>Pete Millington – May 14th, 2021</p>
                                    </div>
                                </div>
                            </div>
                            <div className="arrow-separator"></div>
                            <div className='lp-horizontal-logo'>
                                <StaticImage src="../../images/lp/SFS-Horizontal.png" alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='lp-solutions'>
                <div className='lp-slice-img-wrapper'>
                    <StaticImage src="../../images/lp/slice_mid.png" alt='' />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 mx-auto">
                            <h2 className='h1 uppercase text-center'>Finally, a solution for Quants designed by Quants.</h2>
                        </div>
                    </div>
                    <ul className='lp-steps'>
                        <li className='lp-steps__item'>
                            <div className='lp-steps__item__head'>
                                <span className='no'><StaticImage src="../../images/homepage/icon-chart.svg" alt='' /></span>
                                <p>Research<br /> Effectiveness</p>
                                <span className='plus'><PlusCircle /></span>
                            </div>
                            <span className='plus-mobile'><PlusCircle size="28" /></span>
                        </li>
                        <li className='lp-steps__item'>
                            <div className='lp-steps__item__head'>
                                <span className='no no-grey'><StaticImage src="../../images/homepage/icon-hourglass.svg" alt='' /></span>
                                <p>Data<br /> integration</p>
                                <span className='plus'><PlusCircle /></span>
                            </div>
                            <span className='plus-mobile'><PlusCircle size="28" /></span>
                        </li>
                        <li className='lp-steps__item'>
                            <div className='lp-steps__item__head'>
                                <span className='no no-purple'><StaticImage src="../../images/homepage/icon-connections.svg" alt='' /></span>
                                <p>Modeling<br /> Efficiency</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
            <div className='lp-solutions-base'>
                <div className="container">
                    <div className="text-center mb-4 pb-2">
                        <div className='ml-2'>
                            <StaticImage placeholder='none' width={32} src="../../images/lp/icon-equal.svg" alt='' />
                        </div>
                    </div>
                    <div className="lp-solutions-quotient">
                        <div className="mb-2 px-3">
                            <StaticImage placeholder='none' src="../../images/lp/quotient_logo.png" alt='' />
                        </div>
                        <button onClick={setShowDemo} className='mt-2 btn btn-block btn-outline-grey mt-3'>Request A demo</button>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default LandingB;